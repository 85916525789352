<template>
  <div class="about">
    <div class="about-list">
      
       <div class="about-list-type">
          <div class="about-list-type-title" v-for="(item,index) in typeundex" :key="index">
            <div class="altotle-hangye">{{item.title}}</div>
            <div class="about-leixing-list" v-for="(itema,indexl) in item.child" :key="indexl">
              <div class="suoyuou-biaoti" :class="{'active':item.act == itema.id}" @click="fiaji(index,itema)">{{itema.title}}</div>
            </div>
          </div>
       </div>
       <div class="paixu-shaixyan">
          <div class="paixu-zonghe-list">
            <div class="buanpai" :class="tiltundex==0?'typnd':''" @click="dianji(0)">综合排序</div>
            <div class="buanpai" :class="tiltundex==1?'typnd':''"  @click="dianji(1)">最新发布</div>
            <div :class="tiltundex==2?'typnd':''"  @click="dianji(2)">最多学习</div>
          </div>
          <!-- 课程中心 -->
          <div class="kexhnrgjek-list">
              <div class="tuijian-kecheng-xuxilist">
                <div class="tuijian-xuxilist-anlisr" :class="(index + 1) % 4  == 0 ? 'meleanli':''" v-for="(item,index) in listtype" :key="index">
                  <div class="tuijian-xuanlist-anli-img"><img  :src="item.img"/></div>
                  <div class="tuijian-xuanlist-biaoti">{{item.title}}</div>
                  <div class="kanshu-anniu-lost">
                    <div class="tuijian-renshu-zong">
                      <div><img style=" width: 14px;height: 14px;margin-right: 5px;" src="../assets/image/huiyan.png"/></div>
                      <div>{{item.browse_number}}</div>
                    </div>
                    <div class="kanshu-anniu-btn"  @click="aboutdetls(item)">立即学习</div>
                  </div>
                </div>
              </div>
          </div>
       </div>
        <!-- 分页 -->
      <div class="fenye-list">
        <Pagination
          :total="total"
          :page.sync="listQuery.PageNo"
          :limit.sync="listQuery.PageSize"
          @pagination="getList"
        />
      </div>
      
    </div>
    
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
  export default {
    name:"about",
    components: { Pagination,},
    data () {
      return {
        input:'',
        mocten:[{
          name:'美业'
        }],
        show:false,
        typelist:0,
        tiltundex:0,
        listQuery: {
          PageNo: 1,
          PageSize: 16,
        },
        total: 0,
        typelistab:{
          type:''
        },
        typeundex:[],
        activelist:7,
        kechengid:{
          industry_course_center_id:'0',
          course_course_center_id:'0',
          dev_course_center_id:'0'
        },
        listtype:[]
      }
    },
    mounted () {
     this.fenlei()
     this.getList()
    },
    methods: {
      // 综合排序
      dianji(e){
        this.tiltundex = e
        console.log(this.tiltundex)
        this.getList()
      },
      // 分类切换
       fiaji(e,f){
        this.typeundex[e].act = f.id
        this.kechengid.industry_course_center_id = this.typeundex[0].act
        this.kechengid.course_course_center_id = this.typeundex[1].act
        this.kechengid.dev_course_center_id = this.typeundex[2].act
        //this.activelist[e] = f.title
        //this.typelist = f.id
         //this.activelist = e.id
        console.log( this.typeundex[e].act,'###@@@')
        this.getList()
      },
      // 分类列表
      fenlei(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseCenterIndex?type="+ that.typelistab.type,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
            let list = res.data.data.map(item=>{
              if(item.child){
                item.child.unshift({id:0,title:'全部'})
              }
              return {
                act:'0',
                ...item
              }
            })
            this.typeundex = list
            console.log(this.typeundex,'#####')
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
     
      getList(){
        let that = this
        that.$http({
          url: that.baseurl +"/getCourseIndex?industry_course_center_id="+ this.kechengid.industry_course_center_id +'&page='+that.listQuery.PageNo+'&limit='+that.listQuery.PageSize + '&order_set='+that.tiltundex+'&course_course_center_id='+this.kechengid.course_course_center_id+'&dev_course_center_id='+this.kechengid.dev_course_center_id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              that.total = res.data.data.total
              that.listtype = res.data.data.data
              console.log(res.data,'item')  
                  
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      aboutdetls(data){
        this.$router.push('/aboutdetails?id='+data.id)
      },
     
    },
  }

</script>
<style scoped>
  .about{
    width: 100%;
    background-color: #F2F5FB ;
    min-height: 1090px;
  }
  .about-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-top: 87px;
    padding-bottom: 20px;
  }
  .about-list-type{
    width: 100%;
    height: 208px;
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .about-list-type-title{
    display: flex;
    width: 96%;
    margin-right: 2%;
    margin-left: 2%;
    padding: 24px 0 0px 0;
  }
  .altotle-hangye{
    width: 6%;
    text-align: left;
    color: #666666;
    font-size: 16px;
  }
  .about-leixing-list{
    display: inline-block;
    position: relative;
    margin-right: 20px;
  }
  .suoyuou-biaoti{
    /* margin-right: 20px; */
    font-size: 14px;
    color:#333333;
    width: 70px;
    min-width: 70px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    min-width: 100px;
  }
  .active{
    width: 70px;
    color:#275AF5;
    background-color: rgba(39,90,245,0.1);
    border-radius: 2px;
    
  }
  .paixu-shaixyan{
    margin-top: 30px;
    min-height: 630px;
  }
  .paixu-zonghe-list{
    display: flex;
    font-size: 18px;
    color: #333333;
  }
  .buanpai{
    margin-right: 45px;
  }
  .typnd{
    font-weight: 550;
    font-size: 18px;
    color: #275AF5;
  }
  .tuijian-kecheng-xuxilist{
    width: 100%;

  }
   .tuijian-xuxilist-anlisr{
    /* width:calc(33.33% - 13.33px); */
     width:calc(25% - 15px);
    display: inline-block;
    position: relative;
    height: 326px;
    background-color:#FFFFFF ;
    border-radius: 8px 8px 0 0;
    margin-top: 20px;
    margin-right: 20px;
  }
  .tuijian-xuanlist-anli-img{
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-color:  #d3dce6;
  }
  .tuijian-xuanlist-anli-img img{
    width: 100%;
    height: 216px;
    border-radius: 8px 8px 0 0;
  }
  .tuijian-xuanlist-biaoti{
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    padding: 16px 16px;
  }
  .kanshu-anniu-lost{
    display: flex;
    justify-content: space-between;
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }
  .tuijian-renshu-zong{
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 14px;
  }
  .kanshu-anniu-btn{
    width: 96px;
    height: 32px;
    line-height: 32px;
    background-color: #275AF5;
    border-radius: 4px;
    color:#FFFFFF;
    font-size: 16px;
    text-align: center;
  }
   .meleanli{
    margin-right: 0;
  }
  .fenye-list{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
  }
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
}
</style>
